.table {
  table-layout: fixed;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  thead tr th:nth-child(1) { // section
    width: 6rem;
  }

  thead tr th:nth-child(3) { // schedule
    width: 16rem;
  }

  thead tr th:nth-child(4) { // location
    width: 10rem;
  }

  thead tr th:nth-child(5) { // crn
    width: 7rem;
  }

  thead tr th:nth-child(6) { // credits
    width: 7rem;
  }

  thead tr th:nth-child(7) { // seats
    width: 10rem;
  }

  thead tr th:nth-child(8) { // basket button
    width: 6rem;
  }
}
