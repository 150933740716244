.table {
  table-layout: fixed;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  thead tr th:nth-child(1) {
    width: 16rem;
  }

  thead tr th:nth-child(2) {
    width: 7rem;
  }

  thead tr th:nth-child(3) {
    width: 15rem;
  }

  thead tr th:nth-child(4) {
    width: 18rem;
  }

  thead tr th:nth-child(5) { // location
    width: 8rem;
  }

  thead tr th:nth-child(6) {
    width: 8rem;
  }

  thead tr th:nth-child(7) {
    width: 8rem;
  }

  thead tr th:nth-child(8) {
    width: 8rem;
  }

  thead tr th:nth-child(9) {
    width: 5rem;
  }

  thead tr th:nth-child(10) {
    width: 6.5rem;
  }
}
